/* You can add global styles to this file, and also import other style files */
@import "angularMaterial.scss";

@import "~ngx-toastr/toastr.css";

@import "swal.scss";

@import "scrollbar.scss";

@import "isBusy.scss";
/* You can add global styles to this file, and also import other style files */

/* makes sizing simpler */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* remove default spacing */
/* force styling of type through styling, rather than elements */

* {
  margin: 0;
  padding: 0;
  font: inherit;

  //outline: 1px solid red;
}

/* dark mode user-agent-styles */

html {
  //  color-scheme: dark light;
}

/* min body height */

body {
  height: 100vh;
  font-size: 20px;
  font-family: "Questrial", Sans-serif;
  color: #333;

  &::before {
    content: " ";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-position-x: right;
    background-image: url("https://emeni.rs/wp-content/uploads/2022/03/back-3-min-1.png");
    background-repeat: no-repeat;
    transform: scaleY(-1);
    opacity: 0.2;
  }
}

/* responsive images/videos */
img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

.webApp {
  flex: 0 0 460px;
  height: 948px;
  position: relative;
  padding: 21px 15px 30px 14px;

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
    background-color: white;
  }

  .okvir {
    background-image: url("../assets/Okvir.png");

    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    touch-action: none;
  }
}
