.isBusyContainer {
// Nije centrirano, ali za sada neka bude ovako, zbog centriranja reči "Poruči" u korpi
//  display: inline-flex !important;
  align-items: center;
  display: flex !important;

  &.isBusy.white:before {
    background-image: url(/assets/Spinner-1s-40px-white.svg);
  }

  &.isBusy:before {
    content: "";
    background-image: url(/assets/Spinner-1s-40px.svg);
    background-repeat: no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: contain;
    margin-right: 3px;
  }
}
